module.exports = class CheckInOutReport {
	constructor (object) {
		this.UserId = object.UserId;
		this.UserFirstName = object.UserFirstName;
		this.UserLastName = object.UserLastName;
		this.UserEmail = object.UserEmail;
		this.BranchId = object.BranchId;
		this.BranchName = object.BranchName;
		this.BranchLongitude = object.BranchLongitude;
		this.BranchLatitude = object.BranchLatitude;
		this.BranchDst = object.BranchDst;
		this.BranchTimezone = object.BranchTimezone;
		this.RegionalId = object.RegionalId;
		this.RegionalName = object.RegionalName;
		this.OrganizationId = object.OrganizationId;
		this.OrganizationName = object.OrganizationName;
		this.CheckIn = object.CheckIn;
		this.CheckInTimestamp = object.CheckInTimestamp;
		this.CheckOut = object.CheckOut;
		this.CheckOutTimestamp = object.CheckOutTimestamp;
		this.CheckedOutBy = object.CheckedOutBy;
		this.Longitude = object.Longitude;
		this.Latitude = object.Latitude;
		this.HorizontalAccuracy = object.HorizontalAccuracy;
		this.BranchOfficeType = object.BranchOfficeType;
		this.BranchOfficeTypeDisplay = object.BranchOfficeTypeDisplay;
	}
};
