const { formatterFilter } = require('./../../../utils/luxon-formater');
module.exports = class SurveyReport {
	constructor (object) {
		this.SubmitetSurveyId = object.SubmitetSurveyId;
		this.EndWithNotification = object.EndWithNotification ? object.EndWithNotification : null;
		this.NotificationQuestionId = object.NotificationQuestionId ? object.NotificationQuestionId : null;
		this.SurveyId = object.SurveyId;
		this.CreatedAtUTC = object.CreatedAtUTC ? object.CreatedAtUTC : null;
		this.UserName = object.UserName;
		this.UserSurname = object.UserSurname;
		this.UserId = object.UserId;
		this.RoleId = object.RoleId;
		this.RoleName = object.RoleName;
		this.RoleNameDisplay = object.RoleNameDisplay;
		this.BranchOfficeId = object.BranchOfficeId;
		this.BranchOfficeName = object.BranchOfficeName;
		this.BranchDst = object.BranchDst;
		this.BranchOfficeTypeDisplay = object.BranchOfficeTypeDisplay;
		this.RegionalOfficeId = object.RegionalOfficeId;
		this.OrganizationId = object.OrganizationId;
		this.RegionalOfficeName = object.RegionalOfficeName;
		this.OrganizationName = object.OrganizationName;
		this.Answers = object.Answers;
		this.SurveyQuestions = object.SurveyQuestions;
		this.NotifiedUsers = object.NotifiedUsers;
		this.NotifyRecomandation = object.NotifyRecomandation;
		this.NonNotifyRecomandation = object.NonNotifyRecomandation;
		this.SurveyName = object.SurveyName;
		this.SurveyDescription = object.SurveyDescription;
		this.MySubmitions = object.MySubmitions !== null ? object.MySubmitions.map(s => {
			return {
				Answers: s.Answers,
				BranchLatitude: s.BranchLatitude,
				BranchDst: s.BranchDst,
				BranchLongitude: s.BranchLongitude,
				BranchOfficeId: s.BranchOfficeId,
				BranchOfficeName: s.BranchOfficeName,
				BranchOfficeTypeDisplay: s.BranchOfficeTypeDisplay,
				CreatedAtUTC: formatterFilter(s.CreatedAtUTC, 'datetime_short_with_seconds_timezone', s.BranchDst),
				EndWithNotification: s.EndWithNotification,
				Latitude: s.Latitude,
				Longitude: s.Longitude,
				NonNotifyRecomandation: s.NonNotifyRecomandation ? s.NonNotifyRecomandation : null,
				NotificationQuestionId: s.NotificationQuestionId,
				NotifyRecomandation: s.NotifyRecomandation ? s.NotifyRecomandation : null,
				OrganizationId: s.OrganizationId,
				OrganizationName: s.OrganizationName,
				RegionalOfficeId: s.RegionalOfficeId,
				RegionalOfficeName: s.RegionalOfficeName,
				RoleId: s.RoleId,
				RoleName: s.RoleName,
				RoleNameDisplay: s.RoleNameDisplay,
				SubmitetSurveyId: s.SubmitetSurveyId,
				SurveyDescription: s.SurveyDescription,
				SurveyQuestions: s.SurveyQuestions,
				SurveyId: s.SurveyId,
				SurveyName: s.SurveyName,
				UserId: s.UserId,
				UserName: s.UserName,
				UserSurname: s.UserSurname,
			};
		}) : null;
	}
};
