export const LOGIN_LOGO = {
	src: require('./logo_light.png'),
	width: '240px',
	height: '90px',
};

export const DARK_LOGO = {
	src: require('./mainlogo.png'),
	width: '240px',
	height: '90px',
};
