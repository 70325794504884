module.exports = class EnsNotification {
	constructor (object) {
		this.Id = object.AutoId;
		this.OrganizationId = object.OrganizationId;
		this.OrganizationName = object.OrganizationName;
		this.BranchAction = object.BranchAction;
		this.RegionalOfficeId = object.RegionId;
		this.RegionalOfficeName = object.RegionName;
		this.BranchOfficeId = object.BranchId;
		this.BranchOfficeName = object.BranchName;
		this.UserId = object.UserId;
		this.Name = object.Name;
		this.Surname = object.Surname;
		this.BranchLongitude = object.BranchLongitude;
		this.BranchLatitude = object.BranchLatitude;
		this.FromConsoleUserId = object.FromConsoleUserId;
		this.UserFullNameDisplay = object.UserFullNameDisplay;
		this.SecurityOffice = object.SecurityOffice !== null ? object.SecurityOffice : null;
		this.ToMobileUserDetails = object.ToMobileUserDetails;
		this.MessageContent = object.MessageContent;
		this.TimeSentUtc = object.TimeSentUtc;
		this.BranchTimezone = object.BranchTimezone;
		this.BranchOfficeDst = object.BranchOfficeDst;
		this.MessageTitle = object.MessageTitle;
	}
};
