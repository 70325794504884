const libphonenumber = require('libphonenumber-js');

module.exports = class OfficeType {
	constructor (name, surname, email, phone, avatar) {
		this.Name = name;
		this.Surname = surname;
		this.Email = email;
		this.PhoneNumber = phone ? libphonenumber.parsePhoneNumberFromString(phone).formatInternational() : '';
		this.Avatar = avatar;
	}
};
