const { formatterFilter } = require('./../../../utils/luxon-formater');

module.exports = class TimerReport {
	constructor (object) {
		this.Id = object.CheckInOutId;
		this.TimerForAction = object.TimerForAction;
		this.ActionStartTimeUtc = object.ActionStartTimeUtc;
		this.ActionStartDateUtc = object.ActionStartDateUtc;
		this.ActionStartTimeLocal = object.ActionStartTimeLocal;
		this.ActionStartDateLocal = object.ActionStartDateLocal;
		this.ActionStopTimeUtc = object.ActionStopTimeUtc;
		this.ActionStopDateUtc = object.ActionStopDateUtc;
		this.ActionStopTimeLocal = object.ActionStopTimeLocal;
		this.ActionStopDateLocal = object.ActionStopDateLocal;
		this.ActionStartTimestampUserLocal = formatterFilter(object.ActionStartTimestampUserLocal, 'datetime_short_with_seconds_timezone', object.BranchDst);
		this.ActionStopTimestampUserLocal = formatterFilter(object.ActionStopTimestampUserLocal, 'datetime_short_with_seconds_timezone', object.BranchDst);
		this.UserDst = object.UserDst;
		this.Duration = object.Duration;
		this.Latitude = object.Latitude;
		this.Longitude = object.Longitude;
		this.UserProfile = object.UserProfile;
		this.Pings = object.Pings;
		this.OrganizationName = object.UserProfile.OrganizationName;
		this.RegionName = object.UserProfile.SelectedRegionalOfficeName;
		this.BranchName = object.BranchName;
		this.UserFullName = `${object.UserProfile.Name} ${object.UserProfile.Surname}`;
		this.FormatedStartTimestamp = formatterFilter(`${object.ActionStartDateUtc}T${object.ActionStartTimeUtc}`, 'datetime_short_with_seconds_timezone', object.BranchDst);
		this.FormatedStopTimestamp = formatterFilter(`${object.ActionStopDateUtc}T${object.ActionStopTimeUtc}`, 'datetime_short_with_seconds_timezone', object.BranchDst);
		this.SafeStopped = object.SafeStopped;
		this.Alerted = object.Alerted;
		this.BranchLatitude = object.BranchLatitude;
		this.BranchLongitude = object.BranchLongitude;
		this.GeofenceRadius = object.GeofenceRadius;
	}
};
