// const { formatDuration } = require('./../../../utils/luxon-formater');
const libphonenumber = require('libphonenumber-js');

module.exports = class BranchOffice {
	constructor (object) {
		this.Id = object.BranchOfficeId;
		this.OrganizationName = object.OrganizationName;
		this.OrganizationId = object.OrganizationId;
		this.RegionalOfficeId = object.RegionalOfficeId;
		this.RegionalOfficeName = object.RegionalOfficeName;
		this.Name = object.Name;
		this.Code = object.Code;
		this.Address = object.Address;
		this.FormatedAddress = object.Address + ', ' + object.City + ', ' + object.State + ', ' + object.ZipCode + ', ' + object.Country;
		this.City = object.City;
		this.State = object.State;
		this.ZipCode = object.ZipCode;
		this.Country = object.Country;
		this.PhoneNumber = libphonenumber.parsePhoneNumberFromString(object.PhoneNumber).formatInternational();
		this.Email = object.Email;
		this.Latitude = object.Latitude;
		this.Longitude = object.Longitude;
		this.Altitude = object.Altitude;
		this.StatusId = object.StatusId;
		this.Status = object.Status === 'ACTIVE' ? 'Active' : object.Status === 'PENDING' ? 'Pending' : 'Inactive';
		this.TestMode = object.TestMode;
		this.TestNote = object.TestNote;
		this.TestInfo = object.testInfo;
		this.Survey = object.Survey;
		this.BranchAction = object.BranchAction;
		this.BranchOfficeTypeId = object.BranchOfficeTypeId;
		this.BranchOfficeTypeDisplay = process.env.VUE_APP_THEME === 'safebanker' && object.BranchOfficeTypeDisplay === 'Branch / Location' ? 'Branch' : (process.env.VUE_APP_THEME === 'saferesource' && object.BranchOfficeTypeDisplay === 'Branch / Location' ? 'Location' : object.BranchOfficeTypeDisplay);
		this.BranchDst = object.BranchDst;
		this.BranchTimeZone = object.BranchTimeZone;
		this.SecurityOfficePhoneNumber = libphonenumber.parsePhoneNumberFromString(object.SecurityOfficePhoneNumber).formatInternational();
		this.Opening = object.Opening;
		this.Closing = object.Closing;
		this.Servicing = object.Servicing;
		this.WeekSchedule = object.WeekSchedule;
		this.HolidaysTemplate = object.HolidaysTemplate;
		// if (typeof object.AutoCloseTime === 'string') {
		// 	this.AutoCloseTime = object.AutoCloseTime;
		// } else {
		// 	this.AutoCloseTime = formatDuration(object.AutoCloseTime);
		// }
		this.AutoCloseTime = object.AutoCloseTime;
		this.TestInfo = object.TestInfo;
		this.SurveyName = object.SurveyName;
		this.SecurityOfficeId = object.SecurityOfficeId;
		this.SecurityOfficeName = object.SecurityOfficeName;
		this.ChecklistTemplatesData = object.ChecklistTemplatesData;
		this.GeofenceRadius = object.GeofenceRadius;
		this.LastTask = object.Taskk;
		this.CreatedOn = object.CreatedOn;
		this.FirstUseDate = object.FirstUseDate;
		this.LastTask = object.LastTask;
	}
};
