import { LocationServerError, AuthenticationError } from '@/lib/Errors';

export default {
	queryLocationServer (email) {
		const emailParts = email.split('@');

		if (emailParts.length !== 2) {
			return Promise.reject(new AuthenticationError('Could not process email address'));
		}

		const locationServerEndpoint = process.env.VUE_APP_LOCATION_SERVER;
		const domain = emailParts[1];

		return fetch(`${locationServerEndpoint}?latitude=0&longitude=0&organization=${domain}`)
			.then(response => {
				switch (response.status) {
				case 200:
					return response.json();
				case 404:
					throw new LocationServerError('Error determining login server. Please check your email address.');
				default:
					throw new LocationServerError(`Error connecting to server: code ${response.status} ${response.statusText}`);
				}
			})
			.then(json => {
				if (json) {
					return json;
				} else {
					throw new LocationServerError('Error determining login server. Please check your email address.');
				}
			});
	},
};
