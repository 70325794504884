const libphonenumber = require('libphonenumber-js');

module.exports = class User {
	constructor (object) {
		this.Id = object.UserId;
		this.FirstName = object.Name;
		this.LastName = object.Surname;
		this.FullName = `${object.Name} ${object.Surname}`;
		this.Email = object.Email;
		this.Address = object.Address;
		this.City = object.City;
		this.State = object.State;
		this.ZipCode = object.ZipCode;
		this.Country = object.Country;
		this.PhoneNumber = object.PhoneNumber !== null ? libphonenumber.parsePhoneNumberFromString(object.PhoneNumber).formatInternational() : '';
		this.Number = object.Number;
		this.Avatar = object.Avatar;
		this.RoleId = object.RoleId;
		this.RoleName = object.RoleName;
		this.RoleNameDisplay = object.RoleNameDisplay;
		this.OrderWeight = object.OrderWeight;
		this.LangId = object.LangId;
		this.Language = object.Language;
		this.StatusId = object.StatusId;
		this.Status = object.Status === 'ACTIVE' ? 'Active' : object.Status === 'PENDING' ? 'Pending' : 'Inactive';
		this.OrgName = object.OrgName;
		this.OrganizationName = object.OrganizationName;
		this.WorksIn = object.WorksIn ?? null;
		this.GeofenceValidation = object.GeofenceValidation;
		this.CheckInOutData = object.CheckInOutData;
		this.CarrierInfo = object.CarrierInfo;
		this.UserDeviceInfo = object.UserDeviceInfo;
		this.VerifiedTelephone = object.VerifiedTelephone;
		this.NotificationPreferences = object.NotificationPreferences;
		this.FirstAccount = object.FirstAccount;
		this.UserSessionData = object.UserSessionData;
		this.RegionId = object.WorksIn !== null ? object.WorksIn[0].RegionalOfficeId : '';
		this.RegionName = object.WorksIn !== null ? object.WorksIn[0].RegionalOfficeName : '';
		this.BranchId = object.WorksIn !== null ? object.WorksIn[0].BranchOfficeId : '';
		this.BranchName = object.WorksIn !== null ? object.WorksIn[0].BranchOfficeName : '';
		this.CarrierName = object.CarrierInfo !== null && object.CarrierInfo !== undefined ? object.CarrierInfo.CarrierName : '';
		this.CarrierDomain = object.CarrierInfo !== null && object.CarrierInfo !== undefined ? object.CarrierInfo.CarrierDomain : '';
		this.SecurityOfficeId = object.WorksIn !== null && object.WorksIn[0].SecurityOfficeId ? object.WorksIn[0].SecurityOfficeId : '';
		this.SecurityOfficeName = object.WorksIn !== null && object.WorksIn[0].SecurityOfficeName ? object.WorksIn[0].SecurityOfficeName : '';
		this.PersonalTimer = object.PersonalTimer !== null ? object.PersonalTimer : null;
		this.FeatureEnabled = object.FeatureEnabled !== null ? object.FeatureEnabled : false;
		this.Notifications = object.Notifications;
		this.NotificationObject = object.NotificationObject;
		this.AllowNotifications = object.AllowedNotifications;
		this.NotificationsEnabled = object.NotificationsEnabled;
		this.LastLogin = object.LastLogin;
	}
};
