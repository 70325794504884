
module.exports = class DailyReport {
	constructor (object) {
		this.date = object.date;
		this.day = object.day;
		this.BranchOfficeId = object.BranchOfficeId;
		this.BranchOfficeName = object.BranchOfficeName;
		this.RegionalOfficeName = object.RegionalOfficeName;
		this.OrganizationName = object.OrganizationName;
		this.TimeZoneCode = object.TimeZoneCode;
		this.TimeZone = object.TimeZone;
		this.DayStatus = object.DayStatus;
		this.Schedule = object.Schedule;
		this.tasksOpenings = object.tasksOpenings ? object.tasksOpenings : null;
		this.tasksClosings = object.tasksClosings ? object.tasksClosings : null;
	}
};
