const libphonenumber = require('libphonenumber-js');
const ContactData = require('./ContactData');

module.exports = class Organization {
	constructor (object) {
		this.Id = object.OrganizationId;
		this.Name = object.Name;
		this.Address = object.Address;
		this.City = object.City;
		this.State = object.State;
		this.ZipCode = object.ZipCode;
		this.Country = object.Country;
		this.PhoneNumber = object.PhoneNumber !== null ? libphonenumber.parsePhoneNumberFromString(object.PhoneNumber).formatInternational() : '';
		this.Email = object.Email;
		this.Latitude = object.Latitude;
		this.Longitude = object.Longitude;
		this.Altitude = object.Altitude;
		this.PrimaryContact = new ContactData(object.PrimaryContactName, object.PrimaryContactSurname, object.PrimaryContactEmail, object.PrimaryContactPhone, null);
		this.SecondaryContact = new ContactData(object.SecondaryContactName, object.SecondaryContactSurname, object.SecondaryContactEmail, object.PrimaryContactPhone, null);
		this.StatusId = object.StatusId;
		this.AdminData = object.Admins !== undefined ? object.Admins.map(a => {
			return {
				FirstName: a.AdminName,
				LastName: a.AdminSurname,
				Email: a.AdminEmail,
				PhoneNumber: a.AdminPhoneNumber,
				Avatar: a.AdminAvatar,
				Id: a.AdminId,
				FirstAccount: a.FirstAccount,
				RoleId: a.RoleId,
				RoleNameDisplay: a.RoleNameDisplay,
				StatusId: a.StatusId,
			};
		}) : new ContactData(object.AdminName, object.AdminSurname, object.AdminEmail, object.AdminPhoneNumber, object.AdminAvatar);
		this.OtherAdmins = object.OtherAdmins?.map(a => {
			return {
				FirstName: a.AdminName,
				LastName: a.AdminSurname,
				Email: a.AdminEmail,
				PhoneNumber: a.AdminPhoneNumber,
				Avatar: a.AdminAvatar,
				Id: a.AdminId,
				FirstAccount: a.FirstAccount,
				RoleId: a.RoleId,
				RoleNameDisplay: a.RoleNameDisplay,
				StatusId: a.StatusId,
			};
		});
		this.SecurityOffices = object.SecurityOffices !== undefined ? object.SecurityOffices.map(so => {
			return {
				Id: so.SecurityOfficeId,
				Name: so.Name,
				Address: so.Address,
				Altitude: so.Altitude,
				City: so.City,
				Code: so.Code,
				Country: so.Country,
				Email: so.Email,
				IsMonitoring: so.IsMonitoring,
				Latitude: so.Latitude,
				Longitude: so.Longitude,
				OfficeTypeId: so.OfficeTypeId,
				PhoneNumber: so.PhoneNumber,
				State: so.State,
				StatusId: so.StatusId,
				ZipCode: so.ZipCode,
			};
		}) : null;
		this.HeadOfficeId = object.headOfficeId;
		this.Dealers = object.Dealers !== undefined ? object.Dealers.map(d => {
			return {
				FirstName: d.Name,
				LastName: d.Surname,
				FullName: `${d.Name} ${d.Surname}`,
				Email: d.DealerEmail,
				PhoneNumber: d.PhoneNumber,
				Avatar: d.Avatar,
				Id: d.UserId,
				DealerAssignedHere: d.DealerAssignedHere,
				Address: d.Address,
				City: d.City,
				State: d.State,
				ZipCode: d.ZipCode,
				Country: d.Country,
				RoleId: d.RoleId,
				RoleNameDisplay: d.RoleNameDisplay,
				StatusId: d.StatusId,
			};
		}) : null;
	}
};
