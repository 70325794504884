module.exports = class Authentication {
	constructor (object) {
		this.AppVersions = object.AppVersions;
		this.NotesCategory = object.NotesCategory;
		this.SecurityOffices = object.SecurityOffices;
		this.SecurityUserSipUri = object.SecurityUserSipUri;
		this.Units = object.Units;
		this.UserInfos = object.UserInfos;
		this.UserProfile = object.UserProfile;
		this.UserSettings = object.UserSettings;
		this.UsersDevicesInfos = object.UsersDevicesInfos;
	}
};
