import Authentication from '@/lib/models/Client/Authentication';
import { AuthenticationError } from '@/lib/Errors';
import $http, { requireAuthToken } from '../../../plugins/axios';

export default {
	async checkAuth () {
		requireAuthToken();
		const response = await $http.post('/api/client/isauthenticated');
		if (response.data?.success) {
			return true;
		}

		throw new AuthenticationError('Invalid username or password');
	},

	async login (email, password) {
		const body = {
			username: email,
			password: password,
			sessiontype: 'WEB',
		};

		const response = await $http.post('/api/client/login', body);

		if (response.data?.success) {
			const userJson = response.data.body.userobject;
			return new Authentication(userJson);
		} else {
			throw new AuthenticationError('Invalid username or password');
		}
	},

	async getExternalToken () {
		requireAuthToken();
		const response = await $http.post('/api/client/externaltoken');
		if (response.data?.success) {
			return response.data.body;
		}

		throw new AuthenticationError('Invalid username or password');
	},

	async getUserData () {
		requireAuthToken();
		const response = await $http.post('/api/client/loginwithtoken');

		if (response.data?.success) {
			const userJson = response.data.body.userobject;
			return new Authentication(userJson);
		} else {
			throw new AuthenticationError('Expired Session');
		}
	},

	async forgotPassword (email) {
		const body = {
			case: 'FORGOT-PASSWORD-LINK',
			email: email,
			version: '2',
		};
		const response = await $http.post('/api/client/forgotpassword', body);

		if (response.data) {
			return response.data;
		}
	},

	async logout () {
		await $http.post('/api/client/logout');
	},
};
