<template>
	<v-app id="main-app">
	<v-container class="fill-height" fluid>
		<v-card class="mx-auto px-10 pb-9" width="450px" :loading="loading">
			<v-card-title class="justify-center pt-12"><img v-bind:src="logo.src" v-bind:style="{ width: logo.width, height: logo.height }"></v-card-title>
			<v-card-subtitle class="text-center py-6 headline">{{ subtitle }}</v-card-subtitle>
			<alert-list-auth />
			<v-card-text class="text-center">
				<v-form ref="form1" v-if="showForm === 1" @submit.prevent="login">
					<v-text-field
						ref="email"
						autocomplete="username"
						label="Email"
						v-model.trim="email"
						name="email"
						outlined
						required
						type="email"
						:rules="[rules.emailRequired, rules.email]"
						@keyup.enter="clickButton" />
					<v-text-field
						label="Password"
						v-model="password"
						name="password"
						outlined
						required
						spellcheck="false"
						:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
						:autocomplete="showPassword ? 'off' : 'current-password'"
						:rules="[rules.passwordRequired]"
						:type="showPassword ? 'text' : 'password'"
						@click:append="showPassword = !showPassword"
						@keyup.enter="clickButton" />
				</v-form>
				<v-form ref="form2" v-if="showForm === 2" @submit.prevent="resetPassword">
					<v-input>{{ $t('passwordResetInstructions') }}</v-input>
					<v-text-field
						v-model.trim="email"
						label="Email"
						name="email"
						type="email"
						:rules="[rules.emailRequired, rules.email]"
						outlined
						required
						autocomplete="username" />
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-btn text v-if="showForm !== 2" @click="showForm = 2">{{ $t('forgotPassword') }}</v-btn>
				<v-btn text v-if="showForm !== 1" @click="showForm = 1">{{ $t('log-in') }}</v-btn>
				<v-spacer />
				<v-btn color="primary" @click="clickButton" :loading="loading">{{nextButton}}</v-btn>
			</v-card-actions>
		</v-card>
		<v-footer color="transparent" absolute padless>
			<v-col class="text-center white--text" cols="12">
				&copy;{{ new Date().getFullYear() }} SaferMobility, LLC &#183; {{ appVersion }}
			</v-col>
		</v-footer>
	</v-container>
	</v-app>
</template>

<script>
import AlertListAuth from '@/components/base/AlertListAuth.vue';
import { LocationServerError, AuthenticationError } from '@/lib/Errors';
import { captureException } from '@sentry/browser';
import { mapState } from 'vuex';
const { LOGIN_LOGO } = require(`@/themes/${process.env.VUE_APP_THEME}/image-paths`);

export default {
	components: {
		AlertListAuth,
	},
	data () {
		return {
			appVersion: process.env.VUE_APP_VERSION,
			logo: LOGIN_LOGO,
			loading: false,
			email: '',
			password: '',
			showForm: 1,
			showPassword: false,
			rules: {
				emailRequired: value => !!value || 'Enter your email address',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || 'Enter a valid e-mail address';
				},
				passwordRequired: value => !!value || 'Enter your password',
			},
		};
	},
	computed: {
		...mapState({
			userObject: state => state.authentication.userObject,
		}),
		nextButton () {
			return this.showForm === 1 ? 'Log In' : 'Submit';
		},
		subtitle () {
			return this.showForm === 1 ? 'Log In' : 'Forgot Password';
		},
	},
	mounted () {
		this.focusEmail();
	},
	methods: {
		async login () {
			if (this.$refs.form1.validate()) {
				// Before starting login, clear alerts
				this.$store.commit('alerts/clear');
				this.loading = true;
				try {
					await this.$store.dispatch('authentication/queryLocationServer', this.email);

					await this.$store.dispatch('getCsrfToken');

					await this.$store.dispatch('authentication/doLogin', { email: this.email, password: this.password });

					// NOTE: By definition, if we get here, the login is good,
					//       because a bad login will throw an exception.

					// After a successful login, clear alerts
					this.$store.commit('alerts/clear');

					// Go to the app
					this.$router.replace(this.$route.query.redirect || '/');

					if (this.userObject) {
						await this.$store.dispatch('setupWebSockets');
					}
				} catch (error) {
					if (error instanceof LocationServerError || error instanceof AuthenticationError) {
						this.$store.commit('alerts/add', {
							auth: true,
							type: 'error',
							message: error.message,
						});
					} else {
						captureException(error);
						this.$store.commit('alerts/add', {
							auth: true,
							type: 'error',
							message: 'Error communicating with server. Please try again.',
						});
					}
				} finally {
					this.loading = false;
				}
			}
		},
		async resetPassword () {
			if (this.$refs.form2.validate()) {
				// Before starting password reset, clear alerts
				this.$store.commit('alerts/clear');
				this.loading = true;
				await this.$store.dispatch('authentication/queryLocationServer', this.email);
				await this.$store.dispatch('getCsrfToken');
				const response = await this.$store.dispatch('authentication/forgotPassword', this.email);
				try {
					this.$store.commit('alerts/add', {
						auth: true,
						type: response.success ? 'success' : 'error',
						message: response.description,
					});
				} catch (error) {
					captureException(error);
					this.$store.commit('alerts/add', {
						auth: true,
						type: 'error',
						message: 'Error communicating with server. Please try again.',
					});
				} finally {
					this.loading = false;
				}
			}
		},
		clickButton () {
			switch (this.showForm) {
			case 1: return this.login();
			case 2: return this.resetPassword();
			}
		},
		focusEmail () {
			this.$refs.email.focus();
		},
	},
};
</script>
<style scoped>
#main-app {
	background: linear-gradient(#557DBE, #3C5886)
}
</style>
