module.exports = class Survey {
	constructor (object) {
		this.Id = object.SurveyId;
		this.Name = object.Name;
		this.Description = object.Description;
		this.NotifyRecomandation = object.NotifyRecomandation;
		this.NonNotifyRecomandation = object.NonNotifyRecomandation;
		this.CreatedAt = object.CreatedAt;
		this.CreatedBy = object.CreatedBy;
		this.UpdatedAt = object.UpdatedAt;
		this.UpdatedBy = object.UpdatedBy;
		this.Questions = object.Questions ? object.Questions : null;
		this.BranchOffices = object.BranchOffices ? object.BranchOffices : null;
		this.UsersForSurvey = object.UsersForSurvey ? object.UsersForSurvey : null;
		this.NotifyUsers = object.NotifyUsers ? object.NotifyUsers : null;
		this.NotifyExternalUsers = object.NotifyExternalUsers ? object.NotifyExternalUsers : null;
		this.StartDate = object.StartDate ? object.StartDate : null;
		this.EndDate = object.EndDate ? object.EndDate : null;
		this.UsedOnce = object.UsedOnce;
	}
};
