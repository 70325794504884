<template>
    <v-list>
		<v-list-item-group
			:active-class="`primary white--text`"
			mandatory
		>
		<router-link v-for="chat in chatSessions" :key="chat.ChatRoomId" custom :to="{ path: `/chat/${chat.ChatRoomId}`}" style="text-decoration: none;">
			<v-list-item @click="answerChat(chat.ChatRoomId, chat.status)" :active-class="`primary white--text`">
				<v-list-item-avatar>
				<v-icon
					v-if="chat.status === 'hold'"
					class="blink"
					color="error"
					large
					dark
				>
					mdi-pause-circle
				</v-icon>
				<v-icon
					v-if="chat.status === 'incoming'"
					class="blink"
					color="white"
					dark
				>
					mdi-message
				</v-icon>
				<v-icon
					v-if="chat.status === 'active'"
					color="white"
					dark
				>
					mdi-message
				</v-icon>
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title>{{ chat.MobileClient.Name }} {{ chat.MobileClient.Surname }}</v-list-item-title>
					<v-list-item-subtitle>{{ libphonenumber.parsePhoneNumberFromString(chat.MobileClient.PhoneNumber).formatInternational() }}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</router-link>
		</v-list-item-group>
        <!-- <ChatPane :dialog="dialog" :chatRoomId="chatRoomId" @close="finishChat()" @hold="holdChat()"></ChatPane> -->
    </v-list>
</template>

<script>
import { mapState } from 'vuex';
const libphonenumber = require('libphonenumber-js');
// import ChatPane from './ChatPane';

export default {
	components: {
		// ChatPane,
	},
	data () {
		return {
			dialog: false,
			chatRoomId: null,
			selectedItem: 1,
			libphonenumber: libphonenumber,
		};
	},
	computed: {
		...mapState({
			chatSessions: state => state.chat.chatSessions,
			consoleEmail: state => state.authentication.userObject.UserInfos.Email,
			messages: state => state.chat.messages,
		}),
	},
	watch: {
		chatSessions (val) {
			if (!this.chatSessions.some(s => s.ChatRoomId === this.chatRoomId)) {
				this.chatRoomId = null;
				this.dialog = false;
			}
		},
	},
	methods: {
		answerChat (chatRoomId, status) {
			const requestObject = {
				param_chat_session_id: chatRoomId,
			};
			this.chatRoomId = chatRoomId;
			switch (status) {
			case 'incoming':
				this.$store.dispatch('chat/answerChatFromConsole', requestObject)
					.then((res) => {
						const messageObject = {
							param_chat_session_id: chatRoomId,
							param_content: 'What is your emergency?',
							param_type: 'TEXT',
							// param_media_file_name: '',
							// param_mobile: '',
							param_console: this.consoleEmail,
						};
						if (res.resflag) {
							this.chatRoomId = chatRoomId;
							this.$store.commit('chat/UPDATE_ACCEPTED_CHAT', chatRoomId);
							this.dialog = true;
							this.$store.dispatch('chat/sendChatMessage', messageObject);
						}
					});
				break;
			case 'hold':
				this.returnToChat(chatRoomId, status);
				break;
			default:
				break;
			}
		},
		holdChat () {
			const requestObject = {
				chat_room_id: this.chatRoomId,
				hold: 'true',
			};
			this.$store.dispatch('chat/holdChat', requestObject)
				.then(res => {
					if (res.resflag) {
						this.$store.commit('chat/UPDATE_CHAT_STATUS', {
							status: 'hold',
							ChatRoomId: this.chatRoomId,
						});
						this.chatRoomId = null;
						this.dialog = false;
					}
				});
		},
		returnToChat (chatRoomId) {
			const requestObject = {
				chat_room_id: chatRoomId,
				hold: 'false',
			};
			this.$store.dispatch('chat/holdChat', requestObject)
				.then(res => {
					if (res.resflag) {
						this.$store.commit('chat/UPDATE_CHAT_STATUS', {
							status: 'active',
							ChatRoomId: chatRoomId,
						});
						this.chatRoomId = chatRoomId;
						this.dialog = true;
					}
				});
		},
		finishChat () {
			const requestObject = {
				param_chat_session_id: this.chatRoomId,
			};
			this.$store.dispatch('chat/finishChat', requestObject)
				.then(res => {
					if (res.resflag) {
						this.$store.commit('chat/CHAT_CLOSED_FROM_WEB', this.chatRoomId);
						this.chatRoomId = null;
						this.dialog = false;
					}
				});
		},
	},
};
</script>

<style scoped>
	.blink {
		animation: blinker 1s linear infinite;
	}
	@keyframes blinker {
		50% {
			opacity: 0;
		}
	}
</style>
